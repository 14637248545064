import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'
import TabsPage from '../views/TabsPage.vue'
import Kvkk from '../views/Kvkk.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   redirect: '/home'
  // },
  {
    path: '/:id',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/',
    redirect: '/tabs/tab1'
  },
  {
    path: '/message/:id',
    component: () => import('../views/ViewMessagePage.vue')
  },
  {
    path: '/f',
    component: TabsPage,
    children: [

      {
        path: ':id',
        component: () => import('@/views/Tab1Page.vue')
      },
      {
        path: 'tab2',
        component: () => import('@/views/Tab2Page.vue')
      },
      {
        path: 'tab3',
        component: () => import('@/views/Tab3Page.vue')
      }
    ]
  },
  {
    path: '/k',
    component: Kvkk,
    children: [

      {
        path: ':id',
        component: () => import('@/views/Kvkk1Id.vue')
      },

    ]
  },
  {
    path: '/k2',
    component: Kvkk,
    children: [

      {
        path: ':id',
        component: () => import('@/views/Kvkk2Id.vue')
      },

    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
