
import { useRoute } from 'vue-router';

import {   IonRadio, IonRadioGroup, IonContent, IonHeader, IonPage, IonRefresher, IonRefresherContent, IonTitle, IonToolbar, alertController, IonModal, IonButton,IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonItem, IonLabel   } from '@ionic/vue';
import { defineComponent,ref  } from 'vue';
import axios from "axios";
import { format, utcToZonedTime } from 'date-fns-tz';

export default defineComponent({
  name: 'HomePage',
  data() {
    return {
      rezervationDate: '00.00.0000',
      rezervationTime: '00:00',
      rezervationName: 'Aylin Çetinkaya',
      status: 1,
      smsStatus: 0,
      smsType: "",
      smsStatusText: "tıklayınız.",
      smsStatusRadioText: "Randevuma Katılıyorum",
      hideStatus: false,
      posts: [],
      errors: [],
      
    }
  },
    setup() {

    const route = useRoute();
    const id = route.params.id;
    const isOpenRef = ref(false);
    const setOpen = (state: boolean) => isOpenRef.value = state;

    return {
      id, isOpenRef, setOpen
    }
  },
  methods: {
    // refresh: (ev: CustomEvent) => {
    //   setTimeout(() => {
    //     ev.detail.complete();
    //   }, 3000);
    // }

         async getPosts() {
                console.log(this.id);

        axios
          .get("https://clinicxo.com/Api_app/rezervation_sms_data/"+this.id)
            .then(response=> {
              console.log(response.data);
            this.rezervationName = response.data.name;
            //date format locale
            const event = new Date(response.data.date);
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' } as const;
            this.rezervationDate = event.toLocaleDateString('tr-TR', options);
            this.rezervationTime = response.data.time;
            this.smsStatus = response.data.status;
            this.smsType = response.data.type;
             if (this.smsStatus == 4) {
            this.smsStatusRadioText = "işlemde";
             this.smsStatusText = "işlemde";
            }


             if (this.smsStatus == 3) {
            this.smsStatusText = "bizi arayin.";
            this.smsStatusRadioText = "Randevu iptal talebi.";
            }
         
            if (this.smsStatus == 2) {
            this.smsStatusText = "bizi arayin.";
            this.smsStatusRadioText = "Randevu tarih değişikliği";
            }
            if (this.smsStatus == 1) {
            this.smsStatusRadioText = "Randevuma katılıyorum.";
            
            }
  })
          .catch(error => {
            this.errors = error;
          });
            console.log(this.smsStatus);
     
      },
         async changeStatus(status_change:any) {
                
console.log(status_change);
this.status = status_change;
      },
          sendData(status:any) {
console.log(status);

        axios
          .get("https://clinicxo.com/Api_app/rezervation_sms_status/"+this.id+"/"+status)
         
          .catch(error => {
            this.errors = error;
          });
            this.smsStatus = status;

             if (this.smsStatus == 4) {
            this.smsStatusRadioText = "işlemde";
             this.smsStatusText = "işlemde";
            }

             if (this.smsStatus == 3) {
            this.smsStatusRadioText = "Randevu iptal talebi.";
             this.smsStatusText = "bizi arayin.";
            }
         
            if (this.smsStatus == 2) {
            this.smsStatusText = "bizi arayin.";
            this.smsStatusRadioText = "Randevu tarih değişikliği";
            }
            if (this.smsStatus == 1) {
            this.smsStatusText = "Randevuma katılıyorum";
            
            }
      },

  },
  mounted: function () {
  this.$nextTick(function () {
this.getPosts();
  
  })
},
  components: {
    IonContent,
    IonHeader,
    
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonTitle,
    IonToolbar,
    IonButton,
    IonCard, IonCardContent, IonCardHeader, IonCardTitle ,
    IonRadioGroup ,IonRadio, 
    
    
    
   
  },
});
